.form {
  h3 {
    margin-bottom: 20px;
  }
}

button.button,
a.cancel {
  padding: 12px;
  margin-top: 20px;
}
